.fondo2Icon,
.fondo3Icon {
  position: absolute;
  object-fit: cover;
}
.fondo3Icon {
  top: 1035px;
  left: 0;
  width: 1920px;
  height: 1264px;
}
.fondo2Icon {
  top: o;
  left: 0;
  width: 1973px;
  height: 1080px;
}
.customization,
.features1 {
  position: absolute;
  display: inline-block;
  text-shadow: 0 4px 8px #000;
  z-index: 2;
}
.features1 {
  top: 0;
  left: 700px;
  font-size: var(--font-size-lg);
  text-align: center;
  width: 174px;
}
.customization {
  top: 111px;
  left: 0;
  font-size: var(--font-size-xl);
  width: 661px;
}
.inOxyspaceCustomization {
  margin-block-start: 0;
  margin-block-end: 0;
}
.inOxyspaceCustomizationContainer {
  position: absolute;
  height: 48.77%;
  width: 41.84%;
  top: 32.8%;
  left: 0;
  text-transform: uppercase;
  -webkit-background-clip: text;
  display: inline-block;
  text-shadow: 0 9px 7px #000;
}
.plataformaIcon {
  position: absolute;
  width: 487.65px;
  top: -110%;
  transform: scaleY(-1);
  height: 161.8px;
}
.brasoasulisquierdoIcon,
.brazoizqazulIcon,
.piernasazulesIcon,
.torsopiernasIcon {
  position: absolute;
  height: 99.68%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.brazoizqazulIcon {
  top: 91.59%;
  right: -283.64%;
  bottom: -91.26%;
  left: 283.64%;
  opacity: 0;
}
.brasoasulisquierdoIcon,
.piernasazulesIcon,
.torsopiernasIcon {
  transform: scaleY(-1);
  transform: scale(-1);
  top: -100%;
}
.brasoasulisquierdoIcon,
.piernasazulesIcon {
  top: 91.26%;
  right: -283.64%;
  bottom: -90.94%;
  left: 283.64%;
  opacity: 0;
}
.brasoasulisquierdoIcon {
  top: 91.4%;
  bottom: -91.07%;
}
.robot {
  position: absolute;
  top: 250px;
  left: 1168.5px;
  width: 470.5px;
  height: 463.5px;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.statsChild,
.statsItem {
  position: absolute;
  top: 289px;
  left: 36.25px;
  border-radius: var(--br-md);
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(255, 255, 255, 0.35),
    rgba(73, 73, 73, 0.6)
  );
  box-shadow: 0 4px 8px #000;
  border: 5px solid var(--color-dimgray);
  box-sizing: border-box;
  width: 291px;
  height: 68px;
}
.statsItem {
  top: 377px;
}
.armorIcon,
.damageIcon,
.speedIcon {
  position: absolute;
  top: 200.11px;
  left: 23.25px;
  width: 310.29px;
  height: 37.74px;
}
.armorIcon,
.damageIcon {
  top: 160.87px;
  width: 310.92px;
}
.damageIcon {
  top: 121px;
}
.dps {
  top: 309px;
  left: 107.25px;
  width: 134px;
  height: 38px;
}
.dps,
.meteorRain,
.stats1 {
  position: absolute;
  display: inline-block;
  text-shadow: 0 4px 8px #000;
}
.stats1 {
  top: 33px;
  left: 16.25px;
  font-size: var(--font-size-lg);
  text-align: center;
  width: 134px;
  height: 38px;
}
.meteorRain {
  top: 397px;
  left: 107.25px;
  width: 231px;
  height: 39px;
}
.dps1Icon,
.dps2Icon {
  position: absolute;
  top: 295px;
  left: 51.25px;
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.dps2Icon {
  top: 386px;
  width: 53px;
  height: 50px;
}
.stats,
.statsInner {
  position: absolute;
  top: -8px;
  left: -12px;
  width: 24px;
  height: 489px;
}
.stats {
  top: 112px;
  left: 1212.75px;
  width: 338.25px;
  height: 473px;
  font-size: var(--font-size-sm);
}
.plateChild {
  position: absolute;
  top: -4px;
  left: -6.39px;
  border-radius: 10px;
  width: 370.86px;
  height: 99px;
}
.oxy {
  top: 25px;
  left: 155px;
  text-shadow: 0 4px 8px #000;
}
.oxy,
.plateItem,
.x {
  position: absolute;
}
.plateItem {
  top: 59.5px;
  left: 60.5px;
  border-top: 1px solid #000;
  box-sizing: border-box;
  width: 237px;
  height: 1px;
}
.x {
  top: 8px;
  left: 30px;
  font-size: 40px;
  color: #3d3d3d;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.pagina3Child,
.plate {
  position: absolute;
  top: 133px;
  left: 754px;
  width: 358px;
  height: 83px;
}
.pagina3Child {
  top: 408px;
  left: 30px;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.67);
  box-shadow: 0 4px 8px #000;
  width: 278px;
  height: 47px;
}
.readMore {
  position: absolute;
  top: 418px;
  left: 54px;
  font-size: var(--font-size-sm);
  text-transform: uppercase;
  color: var(--color-gray-200);
}
.pagina3 {
  position: absolute;
  top: 173px;
  left: 166px;
  width: 1551px;
  height: 713.5px;
}
.inOxyspaceYou,
.innovativeBiomes {
  position: absolute;
  display: inline-block;
}
.innovativeBiomes {
  top: 130px;
  left: 2px;
  font-size: var(--font-size-xl);
  width: 661px;
  text-shadow: 0 4px 8px #000;
}
.inOxyspaceYou {
  height: 43.55%;
  width: 47.36%;
  top: 31.16%;
  left: 0.13%;
  text-transform: uppercase;
  background: linear-gradient(#fff, #fff), #fff;
  -webkit-background-clip: text;
  text-shadow: 0 9px 7px #000;
}
.sinfondoRaroIcon {
  position: absolute;
  top: 217px;
  left: 1301px;
  width: 203px;
  height: 203px;
  object-fit: cover;
  opacity: 0.7;
  filter: blur(2px);
}
.sinfondobosqueIcon {
  position: absolute;
  top: 124px;
  left: 891px;
  width: 398px;
  height: 398px;
  object-fit: cover;
}
.sinfondoFuegoIcon,
.sinfondoMagicIcon {
  position: absolute;
  top: 518px;
  left: 720px;
  width: 285px;
  height: 289px;
  object-fit: cover;
  opacity: 0.9;
  filter: blur(2px);
}
.sinfondoMagicIcon {
  top: 467px;
  left: 1208px;
  width: 239px;
  height: 240px;
}
.exclusiveMascots,
.pagina4Child {
  position: absolute;
  top: 526px;
  left: 16px;
}
.pagina4Child {
  top: 514px;
  left: -10px;
  width: 612.5px;
  height: 289.5px;
}
.bicho1Icon,
.bicho31Icon {
  position: absolute;
  top: 586px;
  left: 135px;
  width: 182px;
  height: 183px;
  object-fit: cover;
}
.bicho31Icon {
  top: 580px;
  left: 388px;
  width: 187px;
  height: 196px;
}
.pagina4 {
  position: absolute;
  top: 1293px;
  left: 164px;
  width: 1499px;
  height: 799px;
}
.features {
  position: relative;
  background-color: black;
  width: 100%;
  height: 2299px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-fugaz-one);
}

.meteor1, .meteor2 {
  animation: float 3s infinite ease-in-out alternate;
}

.meteor1 {
  animation-delay: 0.2s;
}

.meteor2 {
  animation-delay: 2s;
}

@keyframes float {
  100% {
    transform: translateY(6px);
  }
}