html,
body{
  padding: 0;
  margin: 0;
  font-family: Averta;
  background-color: var(--bg);
  color: var(--white);
  font-size: 20px;
  font-weight: 500;

}

body::-webkit-scrollbar {
  display: none;
}


a{
  color: inherit;
  text-decoration: none;
}

:root{
  background-image: linear-gradient(325deg, #020a30 0%, #2a0c31 70%);
  --light-blue: #ffae00;
  --white: #efefef;
}

@font-face {
  font-family: 'Fugaz One';
  src: url('./fonts/Righteous-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Semibold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Regular.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Light.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Thin.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Averta;
  src: url(./fonts/AvertaStd-Extrathin.ttf);
  font-weight: 300;
}

@import url("https://fonts.googleapis.com/css2?family=Fugaz+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  touch-action: pan-y;
  -ms-touch-action: pan-y;
  /* fonts */
  --font-fugaz-one: "Fugaz One";

  /* font sizes */
  --font-size-sm: 20px;
  --font-size-base: 24px;
  --font-size-lg: 32px;
  --font-size-xl: 64px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #261228;
  --color-gray-200: rgba(0, 0, 0, 0.9);
  --color-dimgray: rgba(92, 92, 92, 0.3);
  --color-orange: #ff9b04;

  /* border radiuses */
  --br-md: 15px;
}