.breathe{
  animation: breathe 3s infinite ease-in-out;
}

@keyframes breathe{
  0%, 100%{
    opacity: 1;
  }
  50%{
    opacity: 0.2;
  }
}